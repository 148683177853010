.category-articles-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.3em;
    margin-left: 0;
        padding-left: 0;

    @media only screen and (max-width: $firstbreakpoint) {
        margin-left: 0;
        padding-left: 0;
    }
}

.category-articles {
    //background-color: blue;
    flex: 0 0 82%;
    margin-left: 0;
    //background: green;

    @media only screen and (max-width: $firstbreakpoint) {
        margin-left: 0;
        padding-left: 0.5em;
    }
}

.category-articles-indent {
    border-radius: 0.3em;
    flex: 0 0 6%;
    height: auto;
    //background-color: white;
    //overflow: hidden;
    position: relative;
    //background: red;

    svg {
        //background: red;
        width: 5em;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: $firstbreakpoint) {
            //overflow: hidden;
            svg {
                width: 3em;
                top: 0;
                left: 0;
            }
    }
}