footer {
    margin-top: 4em;
    padding-left: $lh;
    padding-right: $lh;
    @media only screen and (max-width: $firstbreakpoint) {
        margin-bottom: 2em;
    }
}

.footer-copyright {
    display: inline-block;
    font-size: 1.6em;
    font-family: -apple-system, Arial, Helvetica, sans-serif;
}
.footer-created {
    float: right;
    font-size: small;
    font-size: 1.6em;
    font-family: -apple-system, Arial, Helvetica, sans-serif;
    margin-top: $lh;

    @media only screen and (max-width: $firstbreakpoint) {
        float: left;
    }
}