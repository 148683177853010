.article-list {
    @media only screen and (max-width: $firstbreakpoint) {
        margin-left: 0;
        padding-left: 0;
    }
}

.fp-content {
    min-height: calc(100vh - 5em);
}

.category-active {
    background: white;
    border: solid 1px white;
}

.categories {
    li {
        display: inline-block;
        text-transform: capitalize;
        border: 1px solid black;
        border-radius: 0.3em;
        //font-size: 0.7em;
        padding: 0.3em;
        padding-bottom: 0.2em;
        line-height: 1;
        //opacity: 0.5;
        vertical-align: middle;
        margin-bottom: 0.2em;
    }

    li:hover {
        background: white;
        border: solid 1px white;
    }

    .category-active {
        border: solid 1px white;
    }
}

.article-link {
    margin-bottom: 1.2em;
    padding: $lh / 4;
}

.fp-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.fp-col {
    flex: 0 0 60%; 

   @media only screen and (max-width: $firstbreakpoint) {
    flex: 0 0 100%; 
    padding-right: 0;
    }
}

.fp-terms-container {
    flex: 0 0 40%;
    border-left: 1px solid black;
    box-sizing: border-box;
    margin-top: 3em;
    padding-left: 1em;

    @media only screen and (max-width: $firstbreakpoint) {
        flex: 0 0 100%; 
        padding-right: 0;
        // margin-top: $lineheight;
        }
}

.fp-list-container {
    padding-right: 1em;

    @media only screen and (max-width: $firstbreakpoint) {
        flex: 0 0 100%; 
        padding-right: 0;
    }
}


.fp-year {
    display: inline-block;
    margin: 0;
    padding: 0;
    //font-size: 0.8em;
    //border: 1px solid black;
    color: rgba(0,0,0,0.1);
    line-height: 1.4;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin-left: 0.2em;
    border-radius: 0.3em;
    box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
}

.term-container-svg {
    box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
    border-radius: 0.4em; 

    svg {
        width: 100%;
        height: auto;
    }
}