@import '_variables.scss';
@import '_default.scss';
@import '_navigation.scss';
@import '_frontpage.scss';
@import '_article.scss';
@import '_footer.scss';
@import '_category.scss';
@import '_keyterms.scss';
@import '_about.scss';


#container {
    margin-top: 0.5em;
    padding-left: $lh;
    padding-right: $lh;

    @media only screen and (max-width: $firstbreakpoint) {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
}

h1 {
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }

main {
    margin-top: 2em;
}  


.turbolinks-progress-bar {
    height: 5px;
    background-color: white;
  }

  .footer-container {
      position: relative;
      //background: red;
  }

.svg-footer {
    pointer-events: none;
    svg {
        position: absolute;
        //background: red;
        width: 100%;
        left: 0;
        bottom: -4em;
        pointer-events: none;
    }
}

.button-selected.planet {
        background: #fcd7ff;
}

.button-selected.imagination {
        background: #f09243;
}

.button-selected.abstraction {
        background: #9492f4;
}