.article {
    // padding-left: 1em;
    // margin-left: 0.5em;
    //smargin-top: 3 * $lh;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: $firstbreakpoint) {
        padding-left: 0;
        margin-left: 0;
    }
}

.article-subtitle {
    font-size: 1rem;
    border-bottom: 1px solid black;
    display: inline-block;
    font-weight: normal;
    margin-top: 3em;
}

.article-categories {
    margin-top: $lh * 2.3;
}

.note {
    //background: white;
    font-size: 0.8em;
    width: $lh / 1.1;
    height: $lh /1.1;
    display: inline-block;
    text-align: center;
    //font-size: 0.8em;
    vertical-align: top;
    margin-right: 0.em;
    margin-left: 0.5em;
    //text-decoration: underline;
    border-radius: 100em;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.5);
    
}
.article-maintitle {
    font-size: 1.5rem;
    font-weight: normal;
    flex: 0 0 70%;
    color: greeen;
    margin-bottom: $lh;
}

.article-mainsubtitle {
    font-size: 1rem;
    font-weight: normal;
    flex: 0 0 70%;
    color: greeen;
    margin-bottom: $lh;
    //font-style: italic;
}

.article-text {
    flex: 0 0 70%;

    @media only screen and (max-width: $firstbreakpoint) {
        flex: 0 0 100%;
    }
}

.article-notes {
    //background: red;
    
    flex: 0 0 30%;
    font-size: 0.5em;
    padding-left: 1em;

    ul {
        margin-left: 0;
        padding-left: 0;
    }

    @media only screen and (max-width: $firstbreakpoint) {
        flex: 0 0 100%;
        padding-left: 0;
        font-size: 0.8em;
    }
}

.article-footer {
    background: #f4e6ec;
    padding: $lh / 2;
    font-size: 0.8em;
    border-radius: $lh / 4;
    
    p {
        margin-top: 0;
    }
}

.article-image {
    width: 80%;
    padding: 1em;
}