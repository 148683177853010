.about-first-column {
    padding-right: 0.5em;
    flex: 0 0 50%;

    @media only screen and (max-width: $firstbreakpoint) {
        flex: 0 0 100%;
    }
}

.about-second-column {
    padding-left: 0.5em;
    flex: 0 0 50%;

    @media only screen and (max-width: $firstbreakpoint) {
        flex: 0 0 100%;
    }
}