@font-face {font-family: 'CenturySchoolbook';font-weight: normal;font-style: normal;src:url('../fonts/356758_0_unhinted_0.woff2') format('woff2'),url('../fonts/356758_0_unhinted_0.woff') format('woff'),url('../fonts/356758_0_unhinted_0.ttf') format('truetype');}


@font-face {font-family: 'CenturySchoolbook';font-weight: normal;font-style: italic;src:url('../fonts/356758_1_unhinted_0.woff2') format('woff2'),url('../fonts/356758_1_unhinted_0.woff') format('woff'),url('../fonts/356758_1_unhinted_0.ttf') format('truetype');}


@font-face {font-family: 'CenturySchoolbook';font-weight: bold;font-style: normal;src:url('../fonts/356758_2_unhinted_0.woff2') format('woff2'),url('../fonts/356758_2_unhinted_0.woff') format('woff'),url('../fonts/356758_2_unhinted_0.ttf') format('truetype');}

* {
  box-sizing: border-box;
}

body {
  font-family: 'CenturySchoolbook',Helvetica , Arial, sans-serif;
  text-rendering: optimizeLegibility;
  background: #FFF8E4;
  font-feature-settings:"kern" 1;
  font-kerning: normal;
}

html, body{
  color: #200404;
  padding: 0;
  margin: 0;
  width: 100%;
  background: #FFF8E4;
  font-size: 1.9vw;
  line-height: 1.28;

  @media only screen and (max-width: 700px) {
    font-size: 3vw;
  }

  @media only screen and (max-width: 500px) {
    font-size: 20px;
  }
}