
.term-container {
    margin: 0;
    padding: 0;
    font-size: inherit;
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 1em;
    width: 32%;
    background: 0;
    border: 0;    
}

.term-container:focus {
    outline: none;
    transform: scale(0.95);
}

.term-background {
    position: relative;
    width: 100%;
    height: 8em;
    background: white;
    border-radius: $module / 10;
    box-shadow: inset 0px 0px 10px 0px #ABABAB;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.term-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 4em;
    width: 4em;
    background: grey;
    border-radius: 100em;
}
.term-title {
    font-size: 0.7em;
    text-align: center;
    padding-top: 0.4em;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    text-transform: capitalize;

}

.term-background-circle-1 {
    background : linear-gradient(13.87deg, rgba(147, 39, 143, 1) 0%, rgba(129, 65, 126, 1) 16.34%, rgba(109, 92, 109, 1) 37.39%, rgba(102, 102, 102, 1) 49.72%, rgba(119, 119, 119, 1) 58.54%, rgba(138, 138, 138, 1) 71.79%, rgba(149, 149, 149, 1) 85.45%, rgba(153, 153, 153, 1) 100%);;
}

.term-backgrounds-1 {
    background: green;
    background : linear-gradient(13.87deg, rgba(147, 39, 143, 1) 0%, rgba(129, 65, 126, 1) 16.34%, rgba(109, 92, 109, 1) 37.39%, rgba(102, 102, 102, 1) 49.72%, rgba(119, 119, 119, 1) 58.54%, rgba(138, 138, 138, 1) 71.79%, rgba(149, 149, 149, 1) 85.45%, rgba(153, 153, 153, 1) 100%);
}

.term-background-circle-2 {
    background : linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 222, 255, 1) 3.6%, rgba(255, 144, 255, 1) 12.71%, rgba(255, 82, 255, 1) 20.83%, rgba(255, 38, 255, 1) 27.65%, rgba(255, 10, 255, 1) 32.92%, rgba(255, 0, 255, 1) 35.99%, rgba(255, 25, 216, 1) 38.98%, rgba(255, 57, 167, 1) 43.31%, rgba(255, 85, 125, 1) 47.75%, rgba(255, 107, 91, 1) 52.23%, rgba(255, 125, 64, 1) 56.76%, rgba(255, 137, 45, 1) 61.37%, rgba(255, 145, 34, 1) 66.1%, rgba(255, 147, 30, 1) 71.13%, rgba(0, 255, 0, 1) 97.13%);
}
.term-backgrounds-2 {
    background : linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 222, 255, 1) 3.6%, rgba(255, 144, 255, 1) 12.71%, rgba(255, 82, 255, 1) 20.83%, rgba(255, 38, 255, 1) 27.65%, rgba(255, 10, 255, 1) 32.92%, rgba(255, 0, 255, 1) 35.99%, rgba(255, 25, 216, 1) 38.98%, rgba(255, 57, 167, 1) 43.31%, rgba(255, 85, 125, 1) 47.75%, rgba(255, 107, 91, 1) 52.23%, rgba(255, 125, 64, 1) 56.76%, rgba(255, 137, 45, 1) 61.37%, rgba(255, 145, 34, 1) 66.1%, rgba(255, 147, 30, 1) 71.13%, rgba(0, 255, 0, 1) 97.13%);
}

.term-background-circle-3 {
    background : linear-gradient(30deg, rgba(57, 181, 74, 1) 0%, rgba(54, 163, 77, 1) 4.24%, rgba(42, 102, 87, 1) 19.96%, rgba(34, 58, 94, 1) 33.3%, rgba(29, 30, 98, 1) 43.6%, rgba(27, 20, 100, 1) 49.62%, rgba(48, 42, 111, 1) 53.03%, rgba(88, 84, 132, 1) 60.27%, rgba(121, 118, 149, 1) 67.68%, rgba(146, 145, 162, 1) 75.17%, rgba(165, 164, 172, 1) 82.79%, rgba(175, 175, 177, 1) 90.61%, rgba(179, 179, 179, 1) 98.94%);
}

.term-backgrounds-3 {
    background : linear-gradient(-15.35deg, rgba(57, 181, 74, 1) 0%, rgba(54, 163, 77, 1) 4.24%, rgba(42, 102, 87, 1) 19.96%, rgba(34, 58, 94, 1) 33.3%, rgba(29, 30, 98, 1) 43.6%, rgba(27, 20, 100, 1) 49.62%, rgba(48, 42, 111, 1) 53.03%, rgba(88, 84, 132, 1) 60.27%, rgba(121, 118, 149, 1) 67.68%, rgba(146, 145, 162, 1) 75.17%, rgba(165, 164, 172, 1) 82.79%, rgba(175, 175, 177, 1) 90.61%, rgba(179, 179, 179, 1) 98.94%);
}

.term-background-circle-4 {
    background : linear-gradient(20.56deg, rgba(0, 255, 255, 1) 0%, rgba(14, 224, 197, 1) 16.63%, rgba(25, 201, 153, 1) 31.47%, rgba(32, 186, 125, 1) 42.93%, rgba(34, 181, 115, 1) 49.62%, rgba(65, 189, 103, 1) 53.13%, rgba(115, 202, 85, 1) 59.3%, rgba(157, 213, 69, 1) 65.62%, rgba(191, 222, 56, 1) 72.01%, rgba(218, 229, 46, 1) 78.47%, rgba(237, 234, 39, 1) 85.03%, rgba(248, 237, 34, 1) 91.77%, rgba(252, 238, 33, 1) 98.94%);
}
.term-backgrounds-4 {
    background : linear-gradient(20.56deg, rgba(0, 255, 255, 1) 0%, rgba(14, 224, 197, 1) 16.63%, rgba(25, 201, 153, 1) 31.47%, rgba(32, 186, 125, 1) 42.93%, rgba(34, 181, 115, 1) 49.62%, rgba(65, 189, 103, 1) 53.13%, rgba(115, 202, 85, 1) 59.3%, rgba(157, 213, 69, 1) 65.62%, rgba(191, 222, 56, 1) 72.01%, rgba(218, 229, 46, 1) 78.47%, rgba(237, 234, 39, 1) 85.03%, rgba(248, 237, 34, 1) 91.77%, rgba(252, 238, 33, 1) 98.94%);
}