nav {

}

.link-home {
    font-weight: normal;
    letter-spacing: -0.01em;
}

.link-about {
    margin-top: 0.5em;
    margin-right: 0.5em;
    //font-weight: bold;
    float: right;
    font-size: 1.2em;
    // width: 2 * $module;
    // height: $module;
    // background: red;
    // border-radius: $module / 15;
    border-bottom: 1px solid black;
}


.link-home {
    position: relative;
    display: inline-block;
    font-size: 1em;
    font-weight: normal;
    letter-spacing: -0.01em;
    //width: 4 * $module;
    height: $module / 2;
    background: #FF10A4;
    color: black;
    border-radius: $module / 15;
}


.link-home-text {
    display: inline-block;
    margin-top: 0.6em;
    margin-right: 1.2em;
    margin-left: 1.2em;
}

.link-home-text-name {
    font-size: 1.2em;
}

.link-home-text-time {
    font-size: 0.7em;
}

.link-logo {
    position: absolute;
    width: 20em;
    top: 0.1em;
    left: 55%;
    transform: translate(-50%, 0);
    margin-left: 1em;
    //margin-top: 0.5em;
    //background: red;

    svg {
        width: 100%;
    }

    @media only screen and (max-width: $firstbreakpoint) {
            //display: none;
            display: none;
    }

}

.st0{fill:#FDF9E9;}